<!-- eslint-disable vue/valid-v-for -->
<template>
  <CBox>
    <CBox
      mt="50px"
      bg="white"
      minH="100px"
      borderRadius="5px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CBox
        py="20px"
        mx="20px"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
      >
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="18px"
          fontWeight="800"
          color="fontMain"
        >
          Runner Status
        </CText>
      </CBox>

      <CDivider mt="0" />
      <CBox display="flex" justifyContent="center" v-if="loading">
        <CSpinner />
      </CBox>

      <CStack spacing="1px" p="10px">
        <CBox
          v-for="(item, index) in data"
          :key="index"
          mx="20px"
          py="10px"
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          gap="20px"
          borderBottom="1px solid #e0e0e0"
        >
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="15px"
            color="fontMain"
            fontweight="600"
          >
            <CBox d="inline" fontWeight="700"> Language:</CBox>
            {{ item.language }}
          </CText>

          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="15px"
            color="fontMain"
            fontweight="600"
          >
            <CBox d="inline" fontWeight="700"> status:</CBox>
            {{ item.success ? "success" : "error" }}
          </CText>
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="15px"
            color="fontMain"
            fontweight="600"
          >
            <CBox d="inline" fontWeight="700"> execute at:</CBox>
            {{ formatDate(item.execute_at) }}
          </CText>
        </CBox>
      </CStack>
    </CBox>
  </CBox>
</template>

<script>
import ApiService from "@/store/api.service";
import moment from "moment-timezone";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      defaultTimezone: "Asia/Jakarta",
    };
  },
  computed: {},
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;

        const {
          data: { data },
        } = await ApiService.get(`/code-runner-status`);

        this.data = data;
      } catch (e) {
        // console.log(e);
      } finally {
        this.loading = false;
      }
    },
    formatDate(date) {
      return date
        ? moment(date)
            .tz(this.defaultTimezone)
            .format("DD MMMM YYYY [at] h:mm A")
        : "";
    },
  },
};
</script>
